/*  imported from index.less  */

#meepshop { .meepshop-select__index__root {
  width: auto;
}

.meepshop-select__index__dropdown {
  width: auto;
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";