/** ignore meepshop id */
@import '~antd/lib/style/themes/default.less';

@value screenSmMax: 767px;
@text-color: rgba(0, 0, 0, 0.65);
@border-radius: 4px;
@border-radius-base: 4px;
@border-color-split: #e8e8e8;

@btn-text-shadow: none;
@btn-shadow: none;
@btn-primary-shadow: none;

@checkbox-check-bg: transparent;
