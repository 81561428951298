/*  imported from popUp.less */

#meepshop { /* FIXME less dynamic import error*/
.meepshop-meep-ui__productList-popUp__carousel {
  width: 300px;
  padding: 0px;

  & > div:nth-child(1) {
    height: 300px;
    background: transparent !important;
    border: 0px !important;

    img {
      max-width: 300px !important;
      max-height: 300px !important;
    }
  }

  & > div:nth-child(2) {
    margin-top: 15px;

    .meepshop-image__index__wrapper {
      height: 60px;
    }

    img {
      max-width: 60px !important;
      max-height: 60px !important;
    }
  }
}

/* FIXME less dynamic import error*/
.meepshop-meep-ui__productList-popUp__collections {
  padding: 0px 25px 30px !important;

  & > div {
    padding: 0px !important;
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";